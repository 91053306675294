/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';

import { Dimmer, Loader } from 'semantic-ui-react';

import { AiOutlinePrinter } from 'react-icons/ai';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Button } from '@mantine/core';
import { Header, Container } from './styles';
import NavBar from '../../../components/NavBar';

import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';

interface VotoData {
  voto: {
    id: string;
    data_voto: string;
  };
  empregado: {
    id: number;
    nome: string;
    cpf: string;
  };
}

interface ReportData {
  assembleia_id: string;
  assembleia_titulo: string;
  votos: VotoData[];
  totalizadores: Record<string, number>[];
}

interface AssembleiaData {
  id: string;
  titulo: string;
  data_inicio: string;
  data_fim: string;
  ativa?: boolean;
  recebe_votos?: boolean;
  total_votos?: number;
}
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 46,
    paddingHorizontal: 35,
    flexDirection: 'column',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  section: {
    marginBottom: -2,
    fontSize: 12,
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },
  subTitle: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  titleData: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  titleTotal: {
    marginTop: 30,
    fontWeight: 'bold',
    fontSize: 18,
  },
});

interface MyDocumentProps {
  reportData: ReportData;
  user: {
    id: number;
    nome: string;
    sindicato: string;
    sigla: string;
  };
  checked: boolean;
}

// Create Document Component
function MyDocument({
  reportData,
  user,
  checked,
}: MyDocumentProps): JSX.Element {
  return (
    <Document
      title={`Relatório de Votação - ${reportData.assembleia_titulo}`}
      key={`Relatório de Votação - ${reportData.assembleia_titulo}`}
    >
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.section} fixed>
          <Text style={styles.title}>
            {`Relatório Emitido por: ${user.nome}`}
          </Text>
          <Text style={styles.title}>{`${reportData.assembleia_titulo}`}</Text>
          <Text style={styles.subTitle}>
            {`ID da Assembleia: ${reportData.assembleia_id}`}
          </Text>
        </View>

        {!checked &&
          reportData.votos.map(({ voto, empregado }: VotoData) => {
            return (
              <View style={styles.section} key={voto.id} wrap={false}>
                <Text>
                  {`\nDados do voto:      ID: ${voto.id} - Data: ${format(
                    utcToZonedTime(voto.data_voto, 'America/Sao_Paulo'),
                    'dd/MM/yyyy - HH:mm:ss',
                  )}`}
                </Text>
                <Text>Dados do Empregado:</Text>
                <Text>{`Nome: ${empregado.nome} - CPF: ${empregado.cpf}\nID: ${empregado.id}`}</Text>
              </View>
            );
          })}

        <View style={styles.section}>
          <Text style={styles.titleTotal}>TOTAL DE VOTOS DESTA ASSEMBLEIA</Text>
          {reportData.totalizadores.map((totalizador) => {
            return (
              <Text key={totalizador.name}>
                {`Total de votos ${totalizador.name}: ${totalizador.total}`}
              </Text>
            );
          })}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }): string =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

function Reports() {
  const { token, user } = useAuth();
  const [renderReport, setRenderReport] = useState(false);
  const [reportData, setReportData] = useState<ReportData>({} as ReportData);

  const [assembleias, setAssembleias] = useState<AssembleiaData[]>([]);
  const [assembleiaId, setAssembleiaId] = useState('');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  // Create styles

  const handleCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setChecked(e.target.checked);
      setRenderReport(false);
    },
    [setChecked],
  );

  const handleAPI = useCallback(async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    setRenderReport(false);
    setLoading(true);
    if (assembleiaId !== '') {
      try {
        await api
          .get(`votos/reports/all?assembleia_id=${assembleiaId}`, config)
          .then((response) => {
            const dataProcess: ReportData = {} as ReportData;

            const { assembleia: assembleiaData, votos, totais } = response.data;

            const totalizadores = assembleiaData.opcoes.map((opcao: string) => {
              return {
                name: opcao,
                total: totais[opcao.toLowerCase()],
              };
            });
            totalizadores.push({
              name: '',
              total: votos.length,
            });
            dataProcess.totalizadores = totalizadores;

            dataProcess.assembleia_id = assembleiaData.id;
            dataProcess.assembleia_titulo = assembleiaData.titulo;

            dataProcess.votos = votos;

            setReportData(dataProcess);
            setLoading(false);
            setRenderReport(true);
          });
      } catch (error) {
        setReportData({} as ReportData);
        setLoading(false);
      }
    }
  }, [token, assembleiaId]);

  const handlePopulateSelect = useCallback(async () => {
    await api.get('assembleias/?type=select').then((response) => {
      let assembleiasData: Array<AssembleiaData> = [] as Array<AssembleiaData>;
      response.data.forEach((assembleiaData: AssembleiaData): void => {
        const { id, titulo, data_inicio, data_fim, ativa, recebe_votos } =
          assembleiaData;

        assembleiasData = [
          ...assembleiasData,
          {
            id,
            titulo,
            data_inicio,
            data_fim,
            ativa,
            recebe_votos,
          },
        ];
      });
      setAssembleiaId(assembleiasData[0]?.id);
      setAssembleias(assembleiasData);
    });
  }, []);

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      event.preventDefault();
      const { value } = event.target;
      if (value) {
        setAssembleiaId(value);
        setRenderReport(false);
      }
    },
    [],
  );

  useEffect(() => {
    const sindicato = process?.env?.REACT_APP_NOMESINDICATO;
    document.title = `Votação Online ${sindicato}`;
    handlePopulateSelect();
  }, [handlePopulateSelect]);

  return (
    <>
      <Header>
        <img src={process.env.REACT_APP_LOGOAPP} alt="logo_app" />
        <img src={process.env.REACT_APP_LOGOSINDICATO} alt="logo_sindicato" />
      </Header>
      <NavBar />
      <Container>
        <strong>
          {'Selecione a Assembleia e em seguida clique em Gerar Relatório. '}
        </strong>
        <select
          name="assembleiasList"
          id="assembleiasList"
          value={assembleiaId}
          onChange={handleSelectChange}
        >
          {assembleias.map((option, index) => {
            return (
              <option value={option.id} key={option.id} selected={index === 0}>
                {option.titulo}
              </option>
            );
          })}
        </select>

        <Dimmer active={loading}>
          <Loader indeterminate>Gerando Relatório. Aguarde...</Loader>
        </Dimmer>
        <label htmlFor="termo">
          <input
            type="checkbox"
            name="termo"
            id="termo"
            checked={checked}
            onChange={handleCheck}
          />
          <strong>Apenas totais</strong>
        </label>
        <Button leftIcon={AiOutlinePrinter} type="button" onClick={handleAPI}>
          Gerar Relatório
        </Button>

        {renderReport && (
          <PDFViewer>
            <MyDocument reportData={reportData} user={user} checked={checked} />
          </PDFViewer>
        )}
      </Container>
    </>
  );
}

export default Reports;
